import * as React from "react"
import { useTranslation } from "gatsby-plugin-react-i18next";
import Layout from "../components/layout/layout"
import SEO from "../components/seo";
import HomeComponent from "../components/home/home";
import { graphql } from "gatsby";

// markup
const HomePage = ({ data }) => {
  const { t } = useTranslation();
  const siteContent = data.siteContent
  const homeContent = data.homeContent.nodes[0]
  return (
    <main>
      <Layout siteContent={siteContent}>
        <SEO title={t("responsable_digital")} headline />
        <HomeComponent data={data} hideEuroWeek={true} ></HomeComponent>
      </Layout>
    </main>

  )
}

export default HomePage
export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter:{language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    siteContent (language: {eq: $language}) {
      description
      facebookURL
      instagramURL
      twitterURL
      linkedinURL
      editionYear
      programYear
      mediaKit
      language
      cookieURL
      personalDataURL
      banner
    }
    homeContent: allHomeContent (filter: {
      typeHome: {eq: nr}
  }) {
    nodes {
      description
      hideEdito
      editoTitle
      editoImage
      editoContent
      editoLabelButton
      editoUrlButton
      hideCommit
      commitTitle
      commitImage
      commitContent
      commitLabelButton
      commitDocument
      hideLatestNews
      lastNewsTitle
      engagementLabelButton
      ressourceLabelButton
    }
    
    }
    editionArticle: allArticle(sort: {fields: datePublished},limit: 3,filter: {typeArticle: {eq: ressources},language: {eq: $language}, tags: {in: ["accueil-retour-edition-1","accueil-retour-edition-2","accueil-retour-edition-3"]}}) {
      nodes {
        title
        slug
        shortDescription
        thumbnailImage
        tags
        categories
      }
      
    }
    videoArticle: allArticle(sort: {fields: datePublished},limit: 3, filter: {typeArticle: {eq: ressources},language: {eq: $language}, tags: {in: ["accueil-video-1","accueil-video-2","accueil-video-3"]}}) {
      nodes {
        title
        slug
        shortDescription
        thumbnailImage
        tags
        categories
      }
    }
    ressourcesArticles: allArticle(
      limit: 2,
      sort: {fields: datePublished, order: DESC},
      filter: {
        typeArticle: {eq: ressources}, 
        language: {eq: $language},
    }) {
        nodes {
          title
          slug
          shortDescription
          thumbnailImage
          tags
          categories
        }
        
      }
    newsArticle: allArticle(
      sort: {fields: datePublished, order: DESC},
      limit: 1,
      filter: {
        typeArticle: {eq: news},
        language: {eq: $language}
      }) {
      nodes {
        title
        slug
        shortDescription
        thumbnailImage
        tags
        categories
      }
    }
  }
`